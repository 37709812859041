<template>
  <section>
    <div class="funBox">
      <div class="funcTop">
        <img :src="props.value.imgUrl0" />
        <p class="ft1">
          {{ t(`funcPage.${props.value.title0}`)
          }}<span class="ft2">{{ t(`funcPage.${props.value.word0}`) }}</span>
        </p>
      </div>
      <div class="titleBar">
        <!-- 标题 -->
        <div class="titles">
          <p>
            <a href="#overview">{{ t("funcPage.barText1") }}</a>
          </p>
          <p>
            <a href="#Best_practices">{{ t("funcPage.barText2") }}</a>
          </p>
          <p>
            <a href="#Tool_support">{{ t("funcPage.barText3") }}</a>
          </p>
          <p>
            <a href="#Client_cases">{{ t("funcPage.barText4") }}</a>
          </p>
          <p>
            <a href="#More">{{ t("funcPage.barText5") }}</a>
          </p>
        </div>
      </div>
      <div id="overview"></div>
      <div class="box1">
        <div>
          <p class="p1">{{ t(`funcPage.${props.value.title1}`) }}</p>
        </div>
        <div class="word_img1">
          <div class="word1">
            <p style="white-space: pre-line">
              {{ t(`funcPage.${props.value.word1}`) }}
            </p>
          </div>
          <div class="img1">
            <img :src="props.value.imgUrl1" />
          </div>
        </div>
        <div id="Best_practices"></div>
      </div>

      <div class="box2">
        <div>
          <p class="p2">{{ t(`funcPage.${props.value.title2}`) }}</p>
        </div>
        <div class="word_img2">
          <div
            class="wordBox1"
            v-for="(val, index) in props.value.word2"
            :key="index"
          >
            <div class="imgBox1">
              <img :src="val.wUrl" />
            </div>
            <p class="pWord1">{{ t(`funcPage.${val.w1}_${index}`) }}</p>
            <p class="pWord2">{{ t(`funcPage.${val.w2}_${index}`) }}</p>
          </div>
        </div>
        <div id="Tool_support"></div>
      </div>

      <div class="box2_5" v-show="show_ai">
        <div><p class="p3">Automotive Safety GPT</p></div>
        <div class="word_img3">
          <div class="left_img">
            <!-- <img :src="props.value.imgUrl2"> -->
            <img src="../../assets/imgs/ai_pageImg7.png" alt="" />
          </div>
          <div class="right_word">
            <p class="rWord">
              {{ t(`funcPage.ai.word2_5`) }}
            </p>
            <!-- <div class="trialBtn" @click="gotoTrial">
                            <p>{{ t('funcPage.trial') }}</p>
                        </div> -->
          </div>
        </div>
        <div id="Client_cases"></div>
      </div>
      <div class="box3">
        <div>
          <p class="p3">{{ t(`funcPage.${props.value.title3}`) }}</p>
        </div>
        <div class="word_img3">
          <div class="left_img">
            <!-- <img :src="props.value.imgUrl2"> -->
            <p style="display: none">{{ bilibilisrc }}</p>
            <el-carousel
              v-if="show_bilibili && bilibilisrc.length > 1"
              class="bilibili"
              type="card"
              :autoplay="false"
            >
              <el-carousel-item v-for="item in bilibilisrc" :key="item">
                <!-- {{item}} -->
                <iframe
                  height="100%"
                  width="100%"
                  style="border-radius: 1.2rem"
                  :src="item"
                  muted="”true“"
                  scrolling="no"
                  border="0"
                  frameborder="no"
                  framespacing="0"
                  allowfullscreen="true"
                >
                </iframe>
              </el-carousel-item>
            </el-carousel>

            <iframe
              v-if="show_bilibili && bilibilisrc.length == 1"
              class="bilibili"
              height="100%"
              width="100%"
              style="border-radius: 1.2rem"
              :src="bilibilisrc[0]"
              muted="”true“"
              scrolling="no"
              border="0"
              frameborder="no"
              framespacing="0"
              allowfullscreen="true"
            >
            </iframe>

            <img v-if="!show_bilibili" :src="props.value.imgUrl2" />
          </div>
          <div class="right_word">
            <p class="rWord">
              {{ t(`funcPage.${props.value.word3}`) }}
            </p>
            <div class="trialBtn" @click="gotoTrial">
              <p>{{ t("funcPage.trial") }}</p>
            </div>
          </div>
        </div>
        <div id="Client_cases"></div>
      </div>
    </div>
  </section>
  <page-case-resource> </page-case-resource>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";
import { ref, defineProps,nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import PageCaseResource from "@/pages/Pc/PageCaseResource.vue";
import { el } from 'element-plus/es/locale';
const store = useStore();
const lang = computed(() => store.getters.lang);
const route = useRoute();
const router = useRouter();

onMounted(() => {
  // 页面加载时检查路由中的 `refresh=true` 查询参数
  checkAndRefresh();
});

// 监听路由变化，如果路径中有 `refresh=true`，则执行刷新并移除该查询参数
watch(() => route.query.refresh, (newVal) => {
  if (newVal === 'true') {
    checkAndRefresh();
  }
});

const checkAndRefresh = () => {
  if (route.query.refresh === 'true') {
  //   // 刷新页面

  //   // 删除 `refresh` 参数并重定向到相同路径
  //   const { refresh, ...query } = route.query;

  //   console.log(route.path);
    
    
    router.replace({ path: route.path });
    setTimeout(() => {
      window.location.reload()
    }, 1000);


  }
};
const name = computed(() => route.path);
let bilibilisrc = ref([]);
// let bilibilisrc = '//player.bilibili.com/player.html?aid=514917972&bvid=BV1hg411D7V8&cid=814675764&page=1'
// watch(name, (newValue, oldValue) => {
//   console.log("Route path changed from", oldValue, "to", newValue);
//   if (newValue.includes("Demand")) {
//     console.log("router");

//     bilibilisrc.value = [
//       "//player.bilibili.com/player.html?isOutside=true&aid=906233045&bvid=BV1kM4y1Q7sC&cid=990337918&p=1",
//     ];
//   }
// });
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
const props = defineProps(["value"]);
const show_ai = ref(false);
const show_bilibili = ref(false);

onMounted(() => {
  if (route.path == "/funPages/AI") {
    show_ai.value = true;
  }
  console.log(route.path.includes("Demand"));

  if (route.path.includes("Demand")) {
    console.log("mou");
    show_bilibili.value = true;
    bilibilisrc.value = [
      "//player.bilibili.com/player.html?isOutside=true&aid=906233045&bvid=BV1kM4y1Q7sC&cid=990337918&p=1",
    ];
  }
  if (route.path.includes("Test")) {
    console.log("mou");
    show_bilibili.value = true;

    bilibilisrc.value = [
      "//player.bilibili.com/player.html?isOutside=true&aid=472872827&bvid=BV1mK411f7Hh&cid=827688619&p=1",
    ];
  }
  if (route.path.includes("Baseline&Change")) {
    console.log("mou");
    show_bilibili.value = true;

    bilibilisrc.value = [
      "//player.bilibili.com/player.html?isOutside=true&aid=514917972&bvid=BV1hg411D7V8&cid=814675764&p=1",
    ];
  }
  if (route.path.includes("Agile")) {
    show_bilibili.value = true;
    console.log("mou");
    if (lang.value == 'zh'){
      bilibilisrc.value = [
        "//player.bilibili.com/player.html?isOutside=true&aid=222728379&bvid=BV1s8411N72d&cid=962901178&p=1",
      ];
    }else{
      bilibilisrc.value = [
        "https://www.youtube.com/embed/-Pl86xZAlmA?si=UCTJ50ZGLWXuxdSa",
        "https://www.youtube.com/embed/abIaqsC_q18?si=r_3ei8P3OHOprYTW"
      ];
    }
  }
  if (route.path.includes("vModel")) {
    show_bilibili.value = true;
    console.log("mou");
    if (lang.value == 'zh'){
      bilibilisrc.value = [
        "//player.bilibili.com/player.html?isOutside=true&aid=862442471&bvid=BV1YG4y127KZ&cid=956915376&p=1",
        "//player.bilibili.com/player.html?isOutside=true&aid=1104540007&bvid=BV16w4m1Q7Vh&cid=1547055422&p=1",
      ];
    }else{
      bilibilisrc.value = [
        "https://www.youtube.com/embed/W3raOqDgMa8?si=mzy3e0TzdTvEqy8Q",
      ];
    }

  }
  if (route.path.includes("AI")) {
    show_bilibili.value = true;
    console.log("mou");
    if (lang.value == 'zh'){
      bilibilisrc.value = [
        "//player.bilibili.com/player.html?isOutside=true&aid=782977681&bvid=BV1V24y1F7dg&cid=1108645904&p=1",
        "//player.bilibili.com/player.html?isOutside=true&aid=113723603816483&bvid=BV1A2CnYLEBw&cid=27556317771&p=1",
      ];
    }else{
      bilibilisrc.value = [
        "https://www.youtube.com/embed/7WSaG-aEWrg?si=Cf0nSlWdAxBTUJKd",
        "https://www.youtube.com/embed/6qjupxF8ImE?si=VtrwRCUqkecSCiSB",
      ];
    }

  }
});

function gotoTrial() {
  window.open("https://ms.ytdevops.com", "_blank");
}
</script>
<style lang="scss">
.el-carousel__item--card {
  width: 80% !important;
  margin-left: -15%;
}
</style>
<style scoped lang="scss">
.content {
  max-width: 20vh; // 设置一个最大宽度
  white-space: pre-wrap; // 超出这个宽度就直接换行
}
.content1 {
  max-width: 70vh; // 设置一个最大宽度
  white-space: pre-wrap; // 超出这个宽度就直接换行
}
.content2 {
  max-width: 80vh; // 设置一个最大宽度
  white-space: pre-wrap; // 超出这个宽度就直接换行
}
</style>
