<template>
  <section class="price-page2" style="position: relative">
    <div class="bg-img">
      <img src="../../assets/imgs/qiu.png" alt="" />
    </div>
    <div class="header">
      <h2 :class="{ font__en: lang === 'en' }">{{ t("price.mid.title") }}</h2>
      <p :class="{ 'font__en--small': lang == 'en' }">
        {{ t("price.mid.desc") }}
      </p>
    </div>
    <div class="cards">
      <div
        class="price-card-new price-card-new__free"
        :class="{ 'price-card-new__free__en': lang === 'en' }"
      >
        <div class="title">
          <span class="desp"
            ><img src="../../assets/imgs/p2.png" />{{
              t("price.priceCard.version.free1")
            }}</span
          >
          <div class="divider-bar"></div>
        </div>
        <div class="bg-white">
          <ul class="vip" :class="{ 'vip_en': lang === 'en' }"
>
            <li>
              <div class="k" :class="{ k__en: lang == 'en' }">{{ t("price.priceCard.featureList.item1") }}:</div>
              <span class="v">{{ t("price.priceCard.cloud") }}</span>
            </li>
            <li>
              <div class="k" :class="{ k__en: lang === 'en' }">
                {{ t("price.priceCard.featureList.item2") }}:
              </div>
              <span class="v">100</span>
            </li>
            <li>
              <div class="k" :class="{ k__en: lang == 'en' }">{{ t("price.priceCard.featureList.item3") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>

            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item10") }}:
              </span>
              <span class="k__blue">{{ t("price.priceCard.n2") }}</span>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item11") }}:
              </span>
              <span class="k__blue">{{ t("price.priceCard.n3") }}</span>
            </li>
          </ul>
        </div>
        <div style="text-align: center; margin-top: 2.4rem">
          <a
            href="https://ms.ytdevops.com"
            target="_blank"
            class="u-btn u-btn--secondary u-btn--small"
            >{{ t("btn.tryIt") }}</a
          >
        </div>
      </div>
      <div
        class="price-card-new price-card-new__free"
        :class="{ 'price-card-new__free__en': lang === 'en' }"
      >
        <div class="title">
          <span class="desp"
            ><img src="../../assets/imgs/p1.png" />{{
              t("price.priceCard.version.free2")
            }}</span
          >
          <div class="divider-bar"></div>
        </div>
        <div class="bg-white">
          <ul class="vip" :class="{ 'vip_en': lang === 'en' }">
            <li>
              <div class="k" :class="{ k__en: lang == 'en' }">{{ t("price.priceCard.featureList.item1") }}:</div>
              <span class="v">{{ t("price.priceCard.n7") }}</span>
            </li>
            <li>
              <div class="k" :class="{ k__en: lang === 'en' }">
                {{ t("price.priceCard.featureList.item2") }}:
              </div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>
            <li>
              <div class="k" :class="{ k__en: lang == 'en' }">{{ t("price.priceCard.featureList.item3") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>

            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item10") }}:
              </span>
              <span class="k__blue">{{ t("price.priceCard.n2") }}</span>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item11") }}:
              </span>
              <span class="k__blue">{{ t("price.priceCard.n4") }}</span>
            </li>
            <li>
              <span class="k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item12") }}:
              </span>
              <span class="k__blue">{{ t("price.priceCard.n5") }}</span>
            </li>
          </ul>
        </div>
        <div style="text-align: center; margin-top: 2.4rem">
          <a
            target="_blank"
            class="u-btn u-btn--secondary u-btn--small"
           @click="jumpToFooter"
            >{{ t("btn.contact") }}</a
          >
        </div>
      </div>
      <div class="price-card-new price-card-new__enterprise">
        <div class="label" :class="{ label_en: lang == 'en' }">{{ t("price.priceCard.hot") }}</div>

        <div class="title">
          <span class="desp"
            ><img src="../../assets/imgs/p3.png" />{{
              t("price.priceCard.version.enterprise")
            }}</span
          >
          <div class="divider-bar"></div>
        </div>
        <div class="bg-white">
          <ul class="vip" :class="{ 'vip_en': lang === 'en' }">
            <li>
              <div class="k" :class="{ k__en: lang == 'en' }">{{ t("price.priceCard.featureList.item1") }}:</div>
              <span class="v">{{ t("price.priceCard.cloud") }}</span>
            </li>
            <li>
              <div class="k__blue" :class="{ k__en: lang === 'en' }">
                {{ t("price.priceCard.featureList.item2") }}:
              </div>
              <span class="k__blue" >{{ t("price.priceCard.notLimited") }}</span>
            </li>
            <li>
              <div class="k" :class="{ k__en: lang == 'en' }">{{ t("price.priceCard.featureList.item3") }}:</div>
              <span class="v">{{ t("price.priceCard.notLimited") }}</span>
            </li>

            <li>
              <span class="k k__blue" :class="{ k__en: lang == 'en' }"
                >{{ t("price.priceCard.featureList.item10") }}:
              </span>
              <span class="k__blue">{{ t("price.priceCard.n6") }}</span>
            </li>
          </ul>
        </div>
        <div style="text-align: center; margin-top: 2.4rem">
          <a
            href="https://ms.ytdevops.com"
            target="_blank"
            class="u-btn u-btn--tertiary u-btn--small"
            >{{ t("btn.tryIt") }}</a
          >
        </div>
      </div>
      <div class="price-card-new price-card-new__private">
        <div class="title">
          <span class="desp"
            ><img src="../../assets/imgs/p4.png" />{{
              t("price.priceCard.version.selfHosting")
            }}</span
          >
          <div class="divider-bar"></div>
        </div>
        <div
          class="bg-white height-equal"
        >
          <div
            class="private-container"
          >
            <img src="../../assets/imgs/priceicon4.webp" alt="" />
            <p>{{ t("price.priceCard.plz") }}</p>
            <p>{{ t("price.priceCard.private") }}</p>
          </div>
        </div>
        <div style="text-align: center; margin-top: 2.4rem">
          <a
            class="u-btn u-btn--secondary u-btn--small"
            @click="jumpToFooter"
            >{{ t("btn.contact") }}</a
          >
        </div>
      </div>
    </div>

    <!-- <el-dialog
      id="zdh_web_dialog1"
      v-model="dialogVisible"
      title="Tips"
      width="60%"
    >
      <div class="left">
        <div class="p1">软件咨询</div>
        <div class="p2">填写信息后，我们将联系您为您提供咨询服务</div>
      </div>

      <div class="right">

        <div class="form"> <div> <span>*</span>姓名 </div><el-input v-model="zdh_form.name" placeholder="请输入姓名" /></div>  
        <div class="form"> <div> <span>*</span>企业邮箱</div><el-input v-model="zdh_form.email" placeholder="请输入企业邮箱" /></div>  
        <div class="form"> <div> <span>*</span>电话</div><el-input v-model="zdh_form.phone" placeholder="请输入电话" /></div>
        <div style="margin-top:40px">您想咨询哪个版本？您主要关注软件的哪些功能？</div>  
        <el-input
                id="zdh_textarea"
                v-model="zdh_form.textarea"
                style="width: 100%;margin:20px 0"
                :rows="2"
                type="textarea"
              />

        <div style="text-align: right;">    
          <el-button style="border-color:#004CAA" @click="dialogVisible = false">取消</el-button>
          <el-button color="#004CAA" type="primary" @click="send">确认</el-button> 
        </div>

      </div>

    </el-dialog> -->

    <apply-dialog
      v-show="dialogVisible"
      :dialog_visible="dialogVisible"
      :price="true"
      @close-dialog="closeDialog"
    ></apply-dialog>
  </section>
</template>

<script setup lang="ts">
import ApplyDialog from "@/pages/Pc/ApplyDialog.vue";
import { ElMessage } from 'element-plus'
import { onMounted } from "vue";
import { ref } from 'vue'
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { reactive } from 'vue'
let footer: HTMLElement;
const dialogVisible = ref(false)
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function closeDialog() {
  dialogVisible.value = false;
}
const zdh_form = reactive({
  name:'',
  textarea:'',
  email:'',
  phone:''
})
const route = useRoute();

onMounted(() => {
  console.log(route);
  
    if (route.query.contact=="contact") {
        dialogVisible.value=true
    }
    
  footer = document.querySelector("#footer")!;
});
function send(){
  console.log(zdh_form);
  if (!zdh_form.name) {
    ElMessage({
      message: 'Name is required.',
      type: 'warning',
      plain: true,
    })
    return
  }

  // 验证 phone 是否为空，并且是11位数字
  const phonePattern = /^[0-9]{11}$/
  if (!zdh_form.phone) {
    ElMessage({
      message: 'Phone number is required.',
      type: 'warning',
      plain: true,
    })
    return
  } else if (!phonePattern.test(zdh_form.phone)) {
    ElMessage({
      message: 'Phone number must be 11 digits.',
      type: 'warning',
      plain: true,
    })
    return
  }

  // 验证 email 是否为空，并且符合邮箱格式
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (!zdh_form.email) {
    ElMessage({
      message: 'Email is required.',
      type: 'warning',
      plain: true,
    })
    return
  } else if (!emailPattern.test(zdh_form.email)) {
    ElMessage({
      message: 'Invalid email format.',
      type: 'warning',
      plain: true,
    })
    return
  }
  
}
function jumpToFooter() {
  // form = {
  //   name:'',
  //   textarea:'',
  //   email:'',
  //   phone:''
  // }
  dialogVisible.value = true
  // footer.scrollIntoView({ behavior: "smooth" });
}
</script>
<style lang="scss">
#zdh_web_dialog1{
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    height: 550px;
    display: flex;
    padding: 0 !important;
    .left{
      width: 40%;
      padding: 50px;
      background: url('@/assets/imgs/zdh_dig.png');
      background-size: 100% 100%;
      color: white;
      .p1{
        font-size: 22px;
        font-weight: 600;
      }
      .p2{
        margin-top: 20px;
        font-size: 16px;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 60%;
      padding: 30px;
      .el-textarea{
        flex:1;
      }
      #zdh_textarea{
        height: 100%;

      }
      .form{
        margin-top: 20px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        .el-input{
          width: 70%;
        }
        span{
          color: red;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">

.decorate {
  display: inline-block;
  position: relative;
  &::before {
    position: absolute;
    content: "";
    width: 3.2rem;
    height: 1px;
    background: #333;
    top: 50%;
    left: 1.6rem;
    transform: translateY(-50%);
  }
}
.small {
  transform: scale(0.8);
}
.mianfei {
  color: rgba(37, 92, 181, 1);
  letter-spacing: 1rem;
}
.rotate {
  font-size: 2.4rem;
  color: #777;
}
.private-contact {
  padding: 3.6rem 3.2rem;
  p {
    font-size: 2.8rem;
    color: #777;
  }
  .p2 {
    margin-left: 4.8rem;
    margin-top: -2.4rem;
  }
}

.font__en {
  max-width: 80rem;
  text-align: center;
  font-size: 3.2rem !important;

  &--small {
    font-size: 2rem !important;
  }
}
.price__en {
  letter-spacing: 0rem !important;
}
.k__en {
  text-align: right !important;
}
.height-equal {
  // min-height: 25rem;

  &__en {
    min-height: 56.5rem;
  }
}
.margin-top {
  margin-top: 6.4rem;
  &__en {
    margin-top: 12rem;
  }
}
</style>
