<template>
  <section class="video-page u-bg-primary">
    <div class="video-page__container" style="margin-top: 9rem;">
      <el-carousel type="card" height="65vh" :autoplay="false" v-if="lang=='zh'">
        <el-carousel-item >
          <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="//player.bilibili.com/player.html?aid=834404351&bvid=BV1T34y1F7EP&cid=1358749094&p=1"  muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
        </el-carousel-item>
        <el-carousel-item>
          <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="//player.bilibili.com/player.html?aid=903568032&bvid=BV1GP4y1X7Dx&cid=917689031&p=1"  muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
        </el-carousel-item>
        <el-carousel-item>
          <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="//player.bilibili.com/player.html?aid=514917972&bvid=BV1hg411D7V8&cid=814675764&page=1" muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
        </el-carousel-item>
      </el-carousel>
      <el-carousel type="card" height="65vh" :autoplay="false" v-else>
        <el-carousel-item >
          <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="https://www.youtube.com/embed/CZPBiv_kuiA?si=uVm_j5THPcgrJ75p"  muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
        </el-carousel-item>
        <el-carousel-item>
          <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="https://www.youtube.com/embed/abIaqsC_q18?si=O-1bTNWSTlIYKS04"  muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
        </el-carousel-item>
        <el-carousel-item>
          <iframe height="100%" width="100%" style="border-radius: 1.2rem;" src="https://www.youtube.com/embed/7WSaG-aEWrg?si=8_2Z7ZQWr3hz7YXm" muted=”true“ scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
        </el-carousel-item>
      </el-carousel>
    </div>
  </section>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
const store = useStore();
const lang = computed(() => store.getters.lang);
</script>