<template>
    <div class="gitlab-page">
        <div class="left" :class="{'left-en': lang === 'en'}">
            <div class="logo">
                <a href="https://gitlab.cn/" target="_blank">
                    <img src="../../assets/imgs/gitlabLogo.png" alt="">
                </a>
            </div>
            <div class="content">
                <div class="title">{{t("toolchain.partner.gitlab.content1.title")}}</div>
                <ul>
                    <li>{{t("toolchain.partner.gitlab.content1.li1")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content1.li2")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content1.li3")}}</li>
                </ul>
                <div class="title">{{t("toolchain.partner.gitlab.content2.title")}}</div>
                <ul>
                    <li>{{t("toolchain.partner.gitlab.content2.li1")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content2.li2")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content2.li3")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content2.li4")}}</li>
                </ul>
                <div class="title">{{t("toolchain.partner.gitlab.content3.title")}}</div>
                <ul>
                    <li>{{t("toolchain.partner.gitlab.content3.li1")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content3.li2")}}</li>
                </ul>
                <div class="title">{{t("toolchain.partner.gitlab.content4.title")}}</div>
                <ul>
                    <li>{{t("toolchain.partner.gitlab.content4.li1")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content4.li2")}}</li>
                    <li>{{t("toolchain.partner.gitlab.content4.li3")}}</li>
                </ul>
            </div>
        </div>
        <div class="right" style="flex:1">
            <iframe
              class="bilibili"
              style="height: 35rem; width: 90%;border-radius: 1.2rem;"
              src="//player.bilibili.com/player.html?isOutside=true&aid=402789586&bvid=BV1VV411g7HJ&cid=1177844871&p=1"
              muted="”true“"
              scrolling="no"
              border="0"
              frameborder="no"
              framespacing="0"
              allowfullscreen="true"
            >
            </iframe>
            <!-- <el-carousel :interval="4000" type="card" height="30rem" width="50rem" indicator-position="none" style="width: 60rem;">
                <el-carousel-item> 
                    <img src="../../assets/imgs/gitlabli1.jpg" alt="" style="height: 100%; width: 100%;">
                </el-carousel-item>
                <el-carousel-item> 
                    <img src="../../assets/imgs/gitlabli2.jpeg" alt="" style=" height: 100%; width: 100%;">
                </el-carousel-item>
            </el-carousel> -->
        </div>
    </div>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped>

</style>