<template>
  <div style="background: rgb(245,245,252);padding-top: 5rem;padding-bottom: 5rem;display:flex;align-items: center;
    flex-direction: column;">
    <div style="align-items: center;display:flex">

    <img width="25" height="25" :src="require(`@/assets/imgs/kwhu.png`)" alt="" />
    <h2 style="color:#383838;" >{{ t("aboutUs.new.kehu")}}</h2>
    <div class="moreBox tool_zdh" @click="go_chain">{{t("topBar.automotiveToolchain") }}</div>
    </div>

    <!-- <img :src="require(`@/assets/imgs/huoban.png`)" alt="" style="width:100%" /> -->
    
    <div class="logoss">
        <img v-for="item in logos" :key="item.url" :src="item.img" alt="" @click="goto(item.url)"/>
    </div>

  </div>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
 const router = useRouter();
import PageMore from '@/pages/Pc/PageMore.vue'
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
function gotoMinjie(e) {
    if (e.target.getAttribute('class')!=='moreBox') router.push('/funPages/Agile')
}
function gotovModel(e) {
    if (e.target.getAttribute('class')!=='moreBox') router.push('/funPages/vModel')
}
import { ref } from "vue";
function goto(url){
  window.open(url, "_blank");

}

function go_chain(){
    router.push({ path: "/automotive_toolchain" });
}
const logos = ref([
  {
    img:require(`@/assets/imgs/logo/1.png`),
    url:'http://www.aotecar.com/'
  },
   {
    img:require(`@/assets/imgs/logo/2.png`),
    url:'http://www.tongyuauto.com/'
  }, {
    img:require(`@/assets/imgs/logo/3.png`),
    url:'http://www.leekr.cn/'
  }, {
    img:require(`@/assets/imgs/logo/4.png`),
    url:'https://www.chery.cn/'
  }, {
    img:require(`@/assets/imgs/logo/5.png`),
    url:'https://www.fuerda-china.com/'
  }, {
    img:require(`@/assets/imgs/logo/6.png`),
    url:'https://zh.zcparts.com/'
  }, {
    img:require(`@/assets/imgs/logo/7.png`),
    url:'http://www.whst.com/'
  },{
    img:require(`@/assets/imgs/logo/8.png`),
    url:'http://www.morede.cn/index.html'
  },{
    img:require(`@/assets/imgs/logo/9.png`),
    url:'http://www.dreamove.cn/'
  },{
    img:require(`@/assets/imgs/logo/10.png`),
    url:'https://www.jiangchengtech.com/'
  },{
    img:require(`@/assets/imgs/logo/11.png`),
    url:'https://www.tuopu.com/'
  },{
    img:require(`@/assets/imgs/logo/12.png`),
    url:'http://www.szsoling.com/home'
  },
  ])

</script>

<style scoped lang="scss">
.tool_zdh{
  margin-left: 20px;
  color: white;
  cursor: pointer;
  width: auto;
    padding: 0 20px;
}
.logoss{
    margin-top: 2rem;
    display: grid;
    justify-items: center;
    background: rgb(245,245,252);


    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 3rem;
    grid-column-gap: 5rem;
    img{
        cursor: pointer;
        border-radius: 8px;
        box-shadow:  0px 15px 30px 0px #adadad;
        width: 80%;
    }
}
.page-other-img {
  min-height: 90vh;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 252, 1) 0%,
    rgba(245, 245, 252, 0) 100%
  );

  img {
    &:nth-of-type(odd) {
      width: 40%;
      margin-bottom: 68px;
    }
    &:nth-of-type(even) {
      width: 90%;
      margin-bottom: 134px;
    }
  }
  
  /* Ensure img styles inside .more div are applied correctly */
  .more img {
    width: 40%;
    margin-bottom: 68px;
  }
  
  /* Overriding the .more img styles when necessary to keep even/odd structure intact */
  .more + img {
    width: 90%;
    margin-bottom: 134px;
  }

  .more + img + .more img {
    width: 40%;
    margin-bottom: 68px;
  }

  .more + img + .more + img {
    width: 90%;
    margin-bottom: 134px;
  }
}

.more{
  display: flex;
  justify-content: center;
}
.pMore{
  // margin-top: 0.4rem;
  margin-left: 5rem
}

</style>
