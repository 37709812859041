<template>
    <div class="feysh-technology-page">
        <div class="left" :class="{'left-en': lang === 'en'}">
            <a href="https://feysh.cn/" target="_blank">
                <img src="../../assets/imgs/feyshTechnologyLogo.png" alt="">
            </a>
            <div class="content">
                <div class="title">{{ t("toolchain.partner.feyshTechnology.content1.title") }}</div>
                <div class="content1">{{ t("toolchain.partner.feyshTechnology.content1.content") }}</div>
                <div class="title">{{ t("toolchain.partner.feyshTechnology.content2.title") }}</div>
                <div class="content1">{{ t("toolchain.partner.feyshTechnology.content2.content") }}</div>
                <div class="title">{{ t("toolchain.partner.feyshTechnology.content3.title") }}</div>
                <div class="content1">{{ t("toolchain.partner.feyshTechnology.content3.content") }}</div>
                <div class="title">{{ t("toolchain.partner.feyshTechnology.content4.title") }}</div>
                <div class="content1">{{ t("toolchain.partner.feyshTechnology.content4.content") }}</div>
            </div>
        </div>
        <div class="right" style="flex:1">
            <iframe
              class="bilibili"
              style="height: 35rem; width: 90%;border-radius: 1.2rem;"
              src="//player.bilibili.com/player.html?isOutside=true&aid=1351727271&bvid=BV116421c7RE&cid=1467548226&p=1"
              muted="”true“"
              scrolling="no"
              border="0"
              frameborder="no"
              framespacing="0"
              allowfullscreen="true"
            >
            </iframe>
            <!-- <el-carousel :interval="4000" type="card" height="19.6rem" indicator-position="none" style="width: 60rem;">
                <el-carousel-item>
                    <img src="../../assets/imgs/feyshTechnologyImg1.jpeg" alt="" width="100%" height="100%">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../../assets/imgs/feyshTechnologyImg2.jpeg" alt="" width="100%" height="100%">
                </el-carousel-item>
                <el-carousel-item>
                    <img src="../../assets/imgs/feyshTechnologyImg3.jpeg" alt="" width="100%" height="100%">
                </el-carousel-item>
            </el-carousel> -->
        </div>
    </div>
</template>
<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
</script>

<style scoped>
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>