<template>
    <div class="toolchain-page2">
        <div class="top" :class="{'top-en': lang === 'en'}">
            <div class="title" style="display:flex">
                {{ t("toolchain.toolchainPage2.title") }} 

                <div style="display:flex;align-items: center;margin-left:20px">
                    <div class="git_btn btn" @click="git"></div>
                    <div class="eo_btn btn" @click="eo"></div>
                    <div class="fy_btn btn" @click="fy"></div>
                </div>
         

            </div>
            <div class="content">{{ t("toolchain.toolchainPage2.content") }}</div>
           
        </div>
        <div class="bottom" :class="{'bottom-en': lang === 'en'}">
            <div class="lineImg" :class="{'lineImg-en': lang === 'en'}">
                <div class="hot hot1" @click="ms"></div>
                <div class="hot hot2" @click="ms"></div>
                <div class="hot hot3" @click="git"></div>
                <div class="hot hot4" @click="ms"></div>
                <div class="hot hot5" @click="fy"></div>
                <div class="hot hot6" @click="eo"></div>
                <div class="hot hot7" @click="ms"></div>
                <!-- <div class="boxs">
                    <div class="box">
                        <div class="systemRequirement box11">{{ t("toolchain.toolchainPage2.systemRequirement") }}</div>
                        <div class="systemQualificationTest box11" :class="{'selectedcard': selectedcard === 'systemQualificationTest'}" @click="ejectTurntable('systemQualificationTest')">
                            {{ t("toolchain.toolchainPage2.systemQualificationTest") }}
                            <div class="li" v-if="selectedcard === 'systemQualificationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="systemArchitecture box11">{{ t("toolchain.toolchainPage2.systemArchitecture") }}</div>
                        <div class="systemIntegrationTest box11" :class="{'selectedcard': selectedcard === 'systemIntegrationTest'}"  @click="ejectTurntable('systemIntegrationTest')">
                            {{ t("toolchain.toolchainPage2.systemIntegrationTest") }}
                            <div class="li" v-if="selectedcard === 'systemIntegrationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="SWRequirement box11">{{ t("toolchain.toolchainPage2.SWRequirement") }}</div>
                        <div class="SWQualificationTest box11" :class="{'selectedcard': selectedcard === 'SWQualificationTest'}"  @click="ejectTurntable('SWQualificationTest')">
                            {{ t("toolchain.toolchainPage2.SWQualificationTest") }}
                            <div class="li" v-if="selectedcard === 'SWQualificationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="SWArchitecture box11" :class="{'selectedcard': selectedcard === 'SWArchitecture'}" @click="ejectTurntable('SWArchitecture')">
                            {{ t("toolchain.toolchainPage2.SWArchitecture") }}
                            <div class="li" v-if="selectedcard === 'SWArchitecture'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                        <div class="SWIntegrationTest box11" :class="{'selectedcard': selectedcard === 'SWIntegrationTest'}"  @click="ejectTurntable('SWIntegrationTest')">
                            {{ t("toolchain.toolchainPage2.SWIntegrationTest") }}
                            <div class="li" v-if="selectedcard === 'SWIntegrationTest'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="SWDetailedDesign box11" :class="{'selectedcard': selectedcard === 'SWDetailedDesign'}"  @click="ejectTurntable('SWDetailedDesign')">
                            {{ t("toolchain.toolchainPage2.SWDetailedDesign") }}
                            <div class="li" v-if="selectedcard === 'SWDetailedDesign'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                        <div class="SWUnitVerification box11" :class="{'selectedcard': selectedcard === 'SWUnitVerification'}"  @click="ejectTurntable('SWUnitVerification')">
                            {{ t("toolchain.toolchainPage2.SWUnitVerification") }}
                            <div class="li" v-if="selectedcard === 'SWUnitVerification'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="coding box11" :class="{'selectedcard': selectedcard === 'coding'}"  @click="ejectTurntable('coding')">
                            {{ t("toolchain.toolchainPage2.coding") }}
                            <div class="li" v-if="selectedcard === 'coding'">
                                <turntable-card :message="selectedcard" @selected="receive"></turntable-card>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="friend">
            <eolink-page id="eolink_div" v-show="selected === 'eolink'"></eolink-page>
            <feysh-technology-page id="feyshTechnology_div" v-show="selected === 'feyshTechnology'"></feysh-technology-page>
            <gitlab-page id="gitlab_div" v-show="selected === 'gitlab'"></gitlab-page>
        </div>
    </div>
</template>
<script setup lang="ts">
import EolinkPage from './EolinkPage.vue';
import GitlabPage from './GitlabPage.vue';
import FeyshTechnologyPage from './FeyshTechnologyPage.vue';
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore} from "vuex";
import {ref,nextTick } from 'vue';
import TurntableCard from '@/components/Pc/TurntableCard.vue';

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()

// 判断是哪个页面显示  第三页
const selected = ref('eolink');

// 选择的是哪个
const selectedcard = ref('SWArchitecture');

// 弹出转盘
const ejectTurntable = (str :string) => {
    selectedcard.value = str;
}

// 接受转盘子组件传递的值
const receive = (str: string) => {
    selected.value = str;
}
function ms(){
    window.open("https://ms.ytdevops.com");
}
function eo(){
    selected.value = 'eolink';
    nextTick(()=>{
        document.getElementById("eolink_div").scrollIntoView({ behavior: "smooth", block: "center" });
    })
    
}
function fy(){
    selected.value = 'feyshTechnology';
    nextTick(()=>{
    document.getElementById("feyshTechnology_div").scrollIntoView({ behavior: "smooth", block: "center" });
    })

}
function git(){
    selected.value = 'gitlab';
    nextTick(()=>{
    document.getElementById("gitlab_div").scrollIntoView({ behavior: "smooth", block: "center" });
    })

}

</script>

<style lang="scss" scoped>
.btn{
    width: 80px;
    height: 30px;
  cursor: pointer;
  margin: 0 10px;
}
.git_btn{
    background: url('@/assets/imgs/git_btn.png');
      background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.fy_btn{
    background: url('@/assets/imgs/fy_btn.png');
      background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.eo_btn{
    background: url('@/assets/imgs/eolink_btn.png');
      background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.lineImg{
    background: url('@/assets/imgs/v_model.png');
    background-size: 90% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 0 !important;
    height: 60rem !important;
    .hot{
        // background : rgba(0, 0, 255, 0.386);
        position: absolute;
        cursor: pointer;
        height: 3rem;
    }
    .hot1{
        width: 5%;
        left: 10.4%;
        top: 19%;
    }
    .hot2{
        width: 5.2%;
        left: 17%;
        top: 52%;
    }
    .hot3{
        width: 5.2%;
        left: 20.5%;
        top: 85%;
    }
    .hot4{
        width: 5.2%;
        left: 75%;
        top: 20.5%;
    }
    .hot5{
        width: 5.2%;
        left: 65.9%;
        top: 61%;
    }
    .hot6{
        width: 5.2%;
        left: 71.4%;
        top: 61%;
    }
    .hot7{
        width: 5.2%;
        left: 68.7%;
        top: 83.3%;
    }
}

.lineImg-en{
    background: url('@/assets/imgs/v_model_en.png');
    background-size: 90% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 0 !important;
    height: 60rem !important;
    .hot1{
        width: 5%;
        left: 7.7%;
        top: 21.5%;
    }
    .hot2{
        width: 5.2%;
        left: 17.8%;
        top: 53%;
    }
    .hot3{
        width: 5.2%;
        left: 21.2%;
        top: 86%;
    }
    .hot4{
        width: 5.2%;
        left: 76%;  
        top: 24%;
    }
    .hot5{
        width: 5.2%;
        left: 66.6%;
        top: 62%;
    }
    .hot6{
        width: 5.2%;
        left: 72%;
        top: 62%;
    }
    .hot7{
        width: 5.2%;
        left: 69.4%;
        top: 85%;
    }
}
</style>