<template>
  <div class="card">
    <div class="card__header">
      <slot name="header"></slot>
    </div>
    <div class="card__main">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>
<style lang="scss" scoped>
.card{
  background: linear-gradient(to right, #003CA3, #0489C2);
}
</style>
