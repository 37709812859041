<template>
  <section class="home-page">
    <div class="main-content">
      <div class="mapping-space">
        <img src="../../assets/imgs/mapping-space.png" alt="" />
      </div>
      <h2 class="h">{{ t("homepage.Jumbotron.word") }}</h2>
      <h2 class="h__2" :class="{ 'h__2-en': lang === 'en' }">
        {{ t("homepage.Jumbotron.word2") }}
      </h2>
      <a
        class="u-btn btn"
        :class="{ 'margin-top__en': lang === 'en' }"
        href="https://ms.ytdevops.com"
        target="_blank"
        style="margin-right: 4.8rem"
        >{{ t("btn.btnTrail") }}</a
      >
      <div
        @click="open_share"
        class="u-btn u-btn--transparent"
        >{{ t("btn.demo") }}</div
      >
      <div class="carIndustry">{{ t("homepage.Jumbotron.carIndustry") }}</div>
      <!-- <div class="car-box">
        <div class="carleft carbutton" @click="scrollLeft">
          <img src="../../assets/imgs/carleft.png" alt="" />
        </div>
        <div class="carousel" :style="carouselStyle">
          <div
            class="carIndustry"
            v-for="(industry, index) in visibleIndustries"
            :key="index"
          >
            <a :href="industry.link" target="_blank">
              <img
                :src="industry.imgSrc"
                alt=""
                :style="
                  'width:' +
                  industry.width +
                  'rem;height:' +
                  industry.height +
                  '%;margin-right:' +
                  industry.margin +
                  'rem'
                "
              />
            </a>
          </div>
        </div>
        <div class="carright carbutton" @click="scrollRight">
          <img src="../../assets/imgs/carright.png" alt="" />
        </div>
      </div> -->
    </div>
    <div class="main-image" :class="{ 'main-image-en': lang === 'en' }">
      <!-- <img src="../../assets/imgs/car.png" alt=""> -->
      <img src="../../assets/imgs/shouyecard.png" alt="" />
      <img
        v-show="lang === 'zh'"
        src="../../assets/imgs/shouyecard1-zh.png"
        alt=""
      />
      <img
        v-show="lang === 'en'"
        src="../../assets/imgs/shouyecard1-en.png"
        alt=""
      />
    </div>
  </section>
  <apply-dialog
    v-show="dialog_visible"
    :dialog_visible="dialog_visible"
    @close-dialog="closeDialog"
  ></apply-dialog>
</template>

<script setup lang="ts">
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import ApplyDialog from "@/pages/Pc/ApplyDialog.vue";
import { ref } from "vue";

const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();
const industries = ref([
  {
    link: "http://www.aotecar.com/",
    imgSrc: require("../../assets/imgs/carIndustry1.png"),
    width: "24",
    height: "50",
    margin: "0",
  },
  {
    link: "http://www.tongyuauto.com/",
    imgSrc: require("../../assets/imgs/carIndustry2.png"),
    width: "15",
    height: "50",
    margin: "0",
  },
  {
    link: "http://www.leekr.cn/",
    imgSrc: require("../../assets/imgs/carIndustry4.png"),
    width: "26.1",
    height: "50",
    margin: "4",
  },
  {
    link: "https://www.chery.cn/",
    imgSrc: require("../../assets/imgs/carIndustry3.png"),
    width: "14.1",
    height: "50",
    margin: "10",
  },

  {
    link: "https://www.fuerda-china.com/",
    imgSrc: require("../../assets/imgs/carIndustry5.png"),
    width: "10.1",
    height: "50",
    margin: "4",
  },
  {
    link: "https://zh.zcparts.com/",
    imgSrc: "https://cdn.multi-masters.com/zccooling/common/logo.png",
    width: "20",
    height: "50",
    margin: "5",
  },
  {
    link: "http://www.whst.com/",
    imgSrc: require("../../assets/imgs/tsrk.png"),
    width: "27",
    height: "50",
    margin: "",
  },
]);

const currentIndex = ref(0);
const visibleIndustries = ref(0);
visibleIndustries.value = industries.value.slice(
  currentIndex.value,
  currentIndex.value + 4
);

function scrollLeft() {
  if (currentIndex.value > 0) {
    currentIndex.value--;
    get_list();
  }
}

function scrollRight() {
  if (currentIndex.value < industries.value.length - 4) {
    currentIndex.value++;
    get_list();
  }
}
function get_list() {
  if (currentIndex.value == 0) {
    industries.value[1].margin = 0;
    industries.value[2].margin = 4;
    visibleIndustries.value = industries.value.slice(0, 4);
  }
  if (currentIndex.value == 1) {
    industries.value[1].margin = 4;
    industries.value[2].margin = 6;
    industries.value[3].margin = 6;
    visibleIndustries.value = industries.value.slice(1, 5);
  }
  if (currentIndex.value == 2) {
    industries.value[3].margin = 4;
    visibleIndustries.value = industries.value.slice(2, 6);
  }
  if (currentIndex.value == 3) {
    industries.value[3].margin = 4;
    visibleIndustries.value = industries.value.slice(3, 7);
  }
}
let dialog_visible = ref(false);
import { useRouter,useRoute } from 'vue-router';

 const router = useRouter();
 const route = useRoute();

function open_share(){
  console.log(66666);
  // window.open('/home?contact=contact', "_blank");
  window.location.href = '/home?contact=contact';
  // router.push(
  //   '/home?contact=contact'
  // )
}
import { onMounted } from "vue";

onMounted(() => {
  console.log(route);
    if (route.query.contact=="contact") {
        dialog_visible.value=true
    }
    
});
function closeDialog() {
  dialog_visible.value = false;
}
</script>

<style lang="scss" scoped>
//.h__en{
//  max-width: 75rem;
//  font-size: 3.6rem !important;
//}
//.margin-top__en{
//  margin-top: 8.4rem !important;
//}
</style>
