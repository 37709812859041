<template>
  <section class="page-about">
    <div class="cards">
      <content-card>
        <template #header>
          <p>8H Auto Devops</p>
          <h2>{{ t("aboutUs.main.title") }}</h2>
          <div class="divider-bar"></div>
        </template>
        <template #main>
          <p class="des" style="white-space: pre-wrap;line-height: 2em;">{{ t("aboutUs.main.desc") }}</p>
          <p class="scan">{{ t("aboutUs.main.desc2") }}</p>
          <img :src="require(`@/assets/imgs/about1_${lang}.webp`)" alt="" />
        </template>
      </content-card>

      <content-card3>
        <template #header>
          <h2 style="color: white">{{ t("aboutUs.new.title2") }}</h2>
          <div class="divider-bar"></div>
        </template>
        <template #main>
          <div class="timeline-box">
            <div class="time-box" v-for="item in time_line" :key="item.index">
              <div
                class="time-title"
                v-if="lang == 'zh'"
                @click="time_line_ind = item.index"
              >
                {{ item.time }}
              </div>
              <div
                class="time-title"
                v-else
                @click="time_line_ind = item.index"
              >
                {{ item.en_time }}
              </div>
              <div class="time-btn">
                <div class="time-btn-line"></div>
                <img
                  class="time-btn-left"
                  @click="down_time_line_ind()"
                  v-if="item.index == 1"
                  src="@/assets/imgs/tbl.png"
                  style="width: 25px; height: 25px"
                  alt=""
                />
                <img
                  class="time-btn-right"
                  @click="add_time_line_ind()"
                  v-if="item.index == 5"
                  src="@/assets/imgs/tbr.png"
                  style="width: 25px; height: 25px"
                  alt=""
                />

                <!-- <div v-if="item.index == 5"> &gt; </div> -->

                <img
                  class="time-btn-ling"
                  src="@/assets/imgs/ling0.png"
                  style="width: 15px; height: 15px"
                  alt=""
                />
              </div>
              <div
                class="time-con"
                :style="
                  'visibility:' +
                  (item.index == time_line_ind ? 'visible' : 'hidden')
                "
              >
                <div class="time-con-tit">{{ item.title }}</div>
                <div class="time-con-con">{{ item.desc }}</div>
              </div>
            </div>
          </div>
        </template>
      </content-card3>

      <content-card class="award-card">
        <template #header>
          <h2>{{ t("aboutUs.new.awa") }}</h2>
          <div class="divider-bar"></div>
        </template>
        <template #main>
          <div class="award-box">
            <img
              style="width: 5rem; height: 5rem"
              :src="require(`@/assets/imgs/award.png`)"
              alt=""
            />
            <h3>{{ award_item.title }}</h3>
            <p>{{ award_item.desc }}</p>
            <img
                class="award-btn-left"
                @click="down_award_ind()"
                src="@/assets/imgs/award/albtn.png"
                style="width: 25px; height: 25px"
                alt=""
              />
              <img
                class="award-btn-right"
                @click="add_award_ind()"
                src="@/assets/imgs/award/arbtn.png"
                style="width: 25px; height: 25px"
                alt=""
              />
            <div class="award-img-box">
              <img
                class="award-img"
                :src="award_item.img[lang]"
                :key="award_item.index"
                alt=""
              />
            </div>
            <div style="width: 100%" v-show="award_item_ind === 3">
              <div style="font-size: 10px; width: 100%; margin: 10px 0">
                {{ t("aboutUs.award.bottom4_title") }}
              </div>
              <h5 style="white-space: pre-line; width: 100%">
                {{ t("aboutUs.award.bottom4") }}
              </h5>
            </div>

            <div class="award-dot">
              <div
                class="dot"
                :class="{ 'dot-active': award_item_ind === 0 }"
                @click="set_award_ind(0)"
              ></div>
              <div
                class="dot"
                :class="{ 'dot-active': award_item_ind === 1 }"
                @click="set_award_ind(1)"
              ></div>
              <div
                class="dot"
                :class="{ 'dot-active': award_item_ind === 2 }"
                @click="set_award_ind(2)"
              ></div>
              <div
                class="dot"
                :class="{ 'dot-active': award_item_ind === 3 }"
                @click="set_award_ind(3)"
              ></div>
            </div>
          </div>
        </template>
      </content-card>

      <content-card2></content-card2>
      <content-card style="padding-left: 6.4rem; padding-right: 6.4rem">
        <template #header>
          <h2>{{ t("aboutUs.new.title1") }}</h2>
          <div class="divider-bar"></div>
        </template>
        <template #main>
          <div class="grid-box">
            <div class="tech-box" v-for="item in caseArr" :key="item.title" @click="openWindow(lang == 'en' ? item.url_en : item.url)">
              <div v-if="lang == 'en'">{{ item.title_en }}</div>
              <div v-else>{{ item.title }}</div>
              <div class="tech-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                >
                  <!-- :stroke="item.color=='white'?'rgba(0, 0, 0, 1)':'rgba(255, 255, 255, 1)'" -->
                  <!-- :fill="item.color=='white'?'rgba(0, 0, 0, 1)':'rgba(255, 255, 255, 1)'" -->

                  <circle
                    cx="15.5"
                    cy="15.5"
                    r="15"
                    stroke="rgba(0, 0, 0, 1)"
                    stroke-width="1"
                  ></circle>
                  <path
                    fill="rgba(0, 0, 0, 1)"
                    d="M8.5 15L22.5 15L22.5 16L8.5 16L8.5 15ZM22.8532 15.8532L22.8532 15.8532C22.6581 16.0483 22.3419 16.0483 22.1468 15.8532L18.6148 12.3212C18.4197 12.1261 18.4197 11.8099 18.6148 11.6148L18.6148 11.6148C18.8099 11.4197 19.1261 11.4197 19.3212 11.6148L22.8532 15.1468C23.0483 15.3419 23.0483 15.6581 22.8532 15.8532ZM22.8532 15.1468L22.8532 15.1468C23.0483 15.3419 23.0483 15.6581 22.8532 15.8532L19.3212 19.3852C19.1261 19.5803 18.8099 19.5803 18.6148 19.3852L18.6148 19.3852C18.4197 19.1901 18.4197 18.8739 18.6148 18.6788L22.1468 15.1468C22.3419 14.9517 22.6581 14.9517 22.8532 15.1468Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="info-box" style="margin-top: 30px">
            <a
              class="new_a"
              target="_blank"
              href="https://blog.csdn.net/yuchao_luo?type=blog"
              >{{ t("aboutUs.new.more") }}</a
            >
          </div>
        </template>
      </content-card>
      <content-card3 style="padding-left: 6.4rem; padding-right: 6.4rem">
        <template #header>
          <h2 style="color: white">{{ t("aboutUs.recruiting.title") }}</h2>
          <div class="divider-bar"></div>
        </template>
        <template #main>
          <div class="content-box">
            <div class="row">
              <h3 style="color: white">
                {{ t("aboutUs.recruiting.engineering") }}
              </h3>
              <div class="info-box">
                <div class="box">
                  <h3>{{ t("aboutUs.recruiting.frontend") }}</h3>
                  <div class="img-box">
                    <img src="../../assets/imgs/frontendicon.webp" alt="" />
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456"
                  >{{ t("btn.join") }}</a
                >
              </div>
              <div class="info-box">
                <div class="box">
                  <h3>{{ t("aboutUs.recruiting.backend") }}</h3>
                  <div class="img-box">
                    <img src="../../assets/imgs/backendicon.webp" alt="" />
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456"
                  >{{ t("btn.join") }}</a
                >
              </div>
            </div>
            <div class="row">
              <h3 style="color: white">{{ t("aboutUs.recruiting.design") }}</h3>
              <div class="info-box">
                <div class="box">
                  <h3>{{ t("aboutUs.recruiting.UI") }}</h3>
                  <div class="img-box">
                    <img src="../../assets/imgs/uiuxicon.webp" alt="" />
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456"
                  >{{ t("btn.join") }}</a
                >
              </div>
              <div class="info-box">
                <div class="box">
                  <h3>{{ t("aboutUs.recruiting.devops") }}</h3>
                  <div class="img-box">
                    <img src="../../assets/imgs/devopsicon.webp" alt="" />
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://m-crecruit.liepin.com/job/share/detail-wx?sk=4b07155e0b88f6c7328f2765f6a4e7db&open_id=oKRge087a9jBkokZyB6rNI9b8_c8&nickname=%E5%BE%AE%E4%BF%A1%E7%94%A8%E6%88%B7&province=&sex=0&city=&country=&headimgurl=https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4wqqjLlPZib2zYNeiaAsSugD0NnaiamMIUFtpyyEQUps97RMZJyTCYwXY99Wu81OfR1sNOQfduZkucw/132&nonce=47083&timestamp=1661761198218&signature=4cf08d98eae30c9de710c1f52c68493633849456"
                  >{{ t("btn.join") }}</a
                >
              </div>
            </div>
          </div>
        </template>
      </content-card3>
    </div>
    <div class="cooperation">
      <div class="header">
        <img src="../../assets/imgs/coicon1.webp" alt="" />
        <span>{{ t("aboutUs.cooperations") }}</span>
      </div>
      <div class="logo-box">
        <img
          src="../../assets/imgs/co1.png"
          alt=""
          style="height: 4.4rem"
          @click="openWindow('https://www.eolink.com')"
        />
        <img
          src="../../assets/imgs/co2.png"
          alt=""
          style="height: 4.8rem"
          @click="openWindow('http://gitlab.cn')"
        />
        <img
          src="../../assets/imgs/co8.png"
          alt=""
          style="margin-bottom: -0.6rem"
          @click="openWindow('https://www.digiproto.com/')"
        />
        <img
          src="../../assets/imgs/co4.png"
          alt=""
          style="height: 5.6rem; margin-bottom: -0.6rem"
          @click="openWindow('http://elephant-tech.cn')"
        />
        <img
          src="../../assets/imgs/co6.png"
          alt=""
          style="height: 10rem; margin-bottom: -2.4rem"
          @click="openWindow('https://openoga.com/')"
        />
        <img
          src="../../assets/imgs/co7.png"
          alt=""
          @click="openWindow('http://www.teststars.cc')"
        />
      </div>
      <div class="logo-box" style="max-width: 120rem">
        <img
          src="../../assets/imgs/co5.png"
          alt=""
          style="height: 7.2rem; margin-bottom: -0.8rem"
          @click="openWindow('http://hcis.com.cn')"
        />
        <img
          src="../../assets/imgs/co3.png"
          alt=""
          style="height: 7.2rem; margin-bottom: -0.8rem"
          @click="openWindow('http://fmeamaster.com')"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import ContentCard from "@/components/Pc/ContentCard.vue";
import ContentCard2 from "@/components/Pc/ContentCard2.vue";
import ContentCard3 from "@/components/Pc/ContentCard3.vue";
import useLocale from "@/hooks/useLocale"; // 国际化hook
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { ref, nextTick } from "vue";

function openWindow(url: string) {
  window.open(url, "_blank");
}
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale();

const time_line = ref([
  {
    time: "2021年8月",
    en_time: "2021.8",
    title: t("aboutUs.new.jingli1"),
    index: 1,
    desc: t("aboutUs.new.desc1"),
  },
  {
    time: "2022年9月",
    en_time: "2022.9",
    title: t("aboutUs.new.jingli2"),
    desc: t("aboutUs.new.desc2"),
    index: 2,
  },
  {
    time: "2023年12月",
    en_time: "2023.12",
    title: t("aboutUs.new.jingli3"),
    index: 3,
    desc: t("aboutUs.new.desc3"),
  },
  {
    en_time: "2024.12",
    time: "2024年12月",
    title: t("aboutUs.new.jingli4"),
    index: 4,
    desc: t("aboutUs.new.desc4"),
  },
  {
    en_time: "2025.1",
    time: "2025年1月",
    title: t("aboutUs.new.jingli5"),
    index: 5,
    desc: t("aboutUs.new.desc5"),
  },
]);
const time_line_ind = ref(0);
console.log(time_line);
time_line_ind.value = 1;
function down_time_line_ind() {
  if (time_line_ind.value > 1) {
    time_line_ind.value = time_line_ind.value - 1;
  } else {
    time_line_ind.value = 5;
  }
}

function add_time_line_ind() {
  if (time_line_ind.value == 5) {
    time_line_ind.value = 1;
  } else {
    time_line_ind.value = time_line_ind.value + 1;
  }
}
const caseArr = ref([
  {
    url: "https://mp.weixin.qq.com/s/_aOOusqO_lqZOcFEJO1ujQ",
    title: "云体科技MappingSpace：以创新引领未来，以科技赋能制造",
    color: "white",
    url_en: "https://mp.weixin.qq.com/s/_aOOusqO_lqZOcFEJO1ujQ",
    title_en:
      "Yunti Technology MappingSpace: Leading the Future with Innovation, Empowering Manufacturing with Technology",
  },
  {
    url: "https://mp.weixin.qq.com/s/AyST5dg2-YuOv80DAAHbCg",
    title: "ASPICE 还值得做吗?",
    color: "#2682C1",
    url_en: "https://mp.weixin.qq.com/s/AyST5dg2-YuOv80DAAHbCg",
    title_en: "Is ASPICE Still Worth Doing?",
  },
  {
    url: "https://mp.weixin.qq.com/s/uTAam37UxhmEM4LXFwtRsg",
    title: "汽车软件开发标准，将走向何方？",
    color: "white",
    url_en: "https://mp.weixin.qq.com/s/uTAam37UxhmEM4LXFwtRsg",
    title_en: "Where Will Automotive Software Development Standards Go?",
  },
  {
    url: "https://mp.weixin.qq.com/s/dUqzrmdqOOO-lK5zn-D6wQ",
    title: "如何既满足ASPICE要求，又减少开发过程文档",
    color: "white",
    url_en: "https://mp.weixin.qq.com/s/dUqzrmdqOOO-lK5zn-D6wQ",
    title_en:
      "How to Meet ASPICE Requirements While Reducing Development Process Documentation",
  },
  {
    url: "https://mp.weixin.qq.com/s/mMDYYWVcmtCxov5xN8xcgA",
    url_en:
      "https://www.linkedin.com/pulse/how-baseline-management-done-automotive-industry-yuntitech-vx95c/?trackingId=Ytt3faHAR0G6r9zmo40jaQ%3D%3D",
    title: "汽车行业如何做基线管理？",
    title_en: "How is Baseline Management Done in the Automotive Industry?",
    color: "white",
  },
  {
    url: "https://mp.weixin.qq.com/s?__biz=MzUyODA2MjkyNA==&mid=2247513467&idx=1&sn=17084310e76fdd7112b9f7ef3c0123be&chksm=fa74f5d8cd037ccee38699de1529997e8b333e02b8e85f917a6386e3beb3eb44c5603bef8897&scene=178&cur_album_id=2553264319307464705#rd",
    title: "为什么从蔚来出来创业？ | 罗宇超和他的MappingSpace",
    url_en:
      "https://mp.weixin.qq.com/s?__biz=MzUyODA2MjkyNA==&mid=2247513467&idx=1&sn=17084310e76fdd7112b9f7ef3c0123be&chksm=fa74f5d8cd037ccee38699de1529997e8b333e02b8e85f917a6386e3beb3eb44c5603bef8897&scene=178&cur_album_id=2553264319307464705#rd",
    title_en:
      "Why Did I Start My Own Business After Leaving NIO? | Luo Yuchao and His MappingSpace",
    color: "white",
  },
]);

const award_case = ref([
  {
    index: 1,
    title: t("aboutUs.award.title1"),
    desc: t("aboutUs.award.desc1"),
    img: {
      zh: require(`@/assets/imgs/award/1.png`),
      en: require(`@/assets/imgs/award/1_en.png`),
    },
  },
  {
    index: 2,
    title: t("aboutUs.award.title2"),
    desc: t("aboutUs.award.desc2"),
    img: {
      zh: require(`@/assets/imgs/award/2.png`),
      en: require(`@/assets/imgs/award/2.png`),
    },
  },
  {
    index: 3,
    title: t("aboutUs.award.title3"),
    desc: t("aboutUs.award.desc3"),
    img: {
      zh: require(`@/assets/imgs/award/3.png`),
      en: require(`@/assets/imgs/award/3.png`),
    },
  },
  {
    index: 4,
    title: t("aboutUs.award.title4"),
    desc: t("aboutUs.award.desc4"),
    img: {
      zh: require(`@/assets/imgs/award/4.png`),
      en: require(`@/assets/imgs/award/4_en.png`),
    },
  },
]);

let award_item = ref({});
const award_item_ind = ref(0);
award_item.value = award_case.value[award_item_ind.value];

function down_award_ind() {
  if (award_item_ind.value > 0) {
    award_item_ind.value = award_item_ind.value - 1;
  } else {
    award_item_ind.value = 3;
  }
  award_item.value = award_case.value[award_item_ind.value];
}

function add_award_ind() {
  if (award_item_ind.value == 3) {
    award_item_ind.value = 0;
  } else {
    award_item_ind.value = award_item_ind.value + 1;
  }
  award_item.value = award_case.value[award_item_ind.value];
}

function set_award_ind(ind) {
  award_item_ind.value = ind;
  award_item.value = award_case.value[award_item_ind.value];
}
</script>
<style lang="scss">
.award-card {
  .card__main {
    width: 100%;
  }
}
</style>
<style scoped lang="scss">
.award-dot {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    background: #f0f2f5;
    border-radius: 50%;
    margin: 0 10px;
  }
  .dot-active {
    background: #b6b7ba;
  }
}
.award-card {
  padding-left: 5rem;
  padding-right: 5rem;
  .card__main {
    width: 100%;
  }
}
.award-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .award-btn-left {
    cursor: pointer;
    position: absolute;
    top: 50px;
    left: -30px;
  }
  .award-btn-right {
    cursor: pointer;
    position: absolute;
    top: 50px;
    right: -30px;
  }
  .award-img-box {
    width: 100%;
    .award-img {
      margin-top: 2rem;
      width: 100%;
      height: auto;
    }
  }
}
.grid-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;

  .tech-box {
    cursor: pointer;
    width: 100%;
    // border: 1px solid bisque;
    border-radius: 18px;
    box-shadow: 4px 4px 6px 4px #e1e3e6;
    padding: 40px 20px 80px 20px;
    text-align: center;
    font-weight: bold;
    position: relative;
    .tech-icon {
      margin-top: 10px;
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.tech-box:hover {
  background: #2682c1;
  color: white;
  /* 你的样式 */
}

.timeline-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  color: white;
  .time-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    .time-title {
      cursor: pointer;
    }
    .time-con {
      flex: 1;

      margin: 10px 5px;

      background: linear-gradient(#42dafc, rgba(255, 255, 255, 0));
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .time-con-tit {
        padding: 20px 0 10px 0;
      }
      .time-con-con {
        padding: 0 10px;
        font-size: 14px;
        text-align: left;
        padding-bottom: 30px;
      }
    }
    .time-btn {
      position: relative;
      color: #42dafc;
      .time-btn-ling {
        transform: translateY(2.5px);
      }
      .time-btn-line {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        background: #80a9d6;
      }
      .time-btn-left {
        cursor: pointer;
        position: absolute;
        left: -40px;
        top: 50%;
        transform: translateY(-50%);
      }
      .time-btn-right {
        cursor: pointer;
        position: absolute;
        right: -40px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
