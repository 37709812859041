<template>
    <section>
        <div class="caseCard">
            <div class="allCard" v-for="(val,index) in cardData" :key="index">
                <div class="card_word" >
                    <p class="p1">{{ t(val.title) }}</p>
                    <p class="p2">{{t(val.text) }}</p>
                    <p class="p3" @click="gotoCard">{{ t('client.caseCard.more') }}<img src="../../assets/imgs/arrows.png"></p>
                </div>
                <div class="card_img">
                    <img :src="val.imgUrl">
                </div>
            </div>
        </div>
    </section>

</template>

<script setup lang="ts">
import useLocale from '@/hooks/useLocale' // 国际化hook
import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import { ref,defineProps } from 'vue';
import { useRouter } from 'vue-router';
const store = useStore();
const lang = computed(() => store.getters.lang);
const {
  i18n: { t }, // 解构实例用具t 访问语言变量
} = useLocale()
 const router = useRouter();

// 接受父组件传递的信息
const props = defineProps(['message']);


const cardDict = ref(
    {
        aotecar: {
            "imgUrl": require('../../assets/imgs/aotecar_card.png'),
            "title": 'client.caseCard.title1',
            "text": 'client.caseCard.title1Text'
        },
        leekr: {
            "imgUrl": require('../../assets/imgs/TongyuAutomobile_card.png'),
            "title": 'client.caseCard.title2',
            "text": 'client.caseCard.title2Text'
        },
        Fuerda: {
            "imgUrl": require('../../assets/imgs/Fuerda_card.png'),
            "title": 'client.caseCard.title3',
            "text": 'client.caseCard.title3Text'
        }
    }
)

const cardData = computed(() => {
    let dic = {}
    for (let key in cardDict.value) {
        if (key !== props.message) {
            dic[key] = cardDict.value[key];
        }
    }
    return dic;
})

function gotoCard(e) {
    let pNode = e.target.parentNode;
    if (pNode.querySelector.innerHTML!='') {
        pNode=pNode.parentNode
    }
    let title = pNode.querySelector('.p1').innerHTML;
    if (title.includes('奥特佳')||title.includes('Aotecar')) {
        router.push('/customer_case/aotecar')
    }
    else if (title.includes('利氪科技')||title.includes('LEEKR')) {
        router.push('/customer_case/leekr')
    }
    else if (title.includes('福尔达')||title.includes('Fuerda')) {
        router.push('/customer_case/Fuerda')
    }
    // document.querySelector('a')
    
}


</script>

<style scoped lang="scss">

</style>
